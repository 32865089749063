import { OpenDialogEvent } from "./dialog_popin";

const _container = document.querySelector('main');
if (_container === null) {
	throw new Error('NO_DIALOG_FOUND');
}
const body = document.body;

const container = _container;

//preferences de vue
const pref_view = window.localStorage.getItem('favorite-view');
if (pref_view !== null && container !== null) {
	container.classList.remove("priority", "hierarchy");
	container.classList.add(pref_view);
}
const hide_nan = window.localStorage.getItem('hide_nan');
if (hide_nan !== null) {
	body.classList.add("no_nan");
}

document.addEventListener('submit', (e) => {
	const preferences_form = (<HTMLElement>e.target).closest('#preferences');
	if (!preferences_form) return;
	e.preventDefault();
	const hide_promo_ouput = preferences_form.querySelector('output');
	if (!hide_promo_ouput) {
		throw new Error("NO_OUTPUT_ELEMENT");
	}
	let inputsChecked: NodeListOf<HTMLInputElement> = preferences_form.querySelectorAll(":checked");
	for (const i of inputsChecked) {
		window.localStorage.setItem(i.name, i.value);
		if (i.name == 'hide-promo') {
			const d = new Date(i.value);
			hide_promo_ouput.innerText = "La popin ne sera plus affiché jusqu'au " + d.toLocaleDateString('fr-FR');
		}
		if (i.name == 'hide_nan') {
			body.classList.add("no_nan");
		}
	}
	let inputsNotChecked: NodeListOf<HTMLInputElement> = preferences_form.querySelectorAll("[type=checkbox]:not(:checked)");
	for (const i of inputsNotChecked) {
		window.localStorage.removeItem(i.name);
		if (i.name == 'hide-promo') {
			hide_promo_ouput.innerText = "";
		}
		if (i.name == 'hide_nan') {
			body.classList.remove("no_nan");
		}
	}
});

let promo_expiration: Date | null = null;
export function isPromoEnabled() {
	if (window.screen.width < 768) {
		return false;
	}
	const hide_promo = window.localStorage.getItem('hide-promo');
	if (hide_promo === null) {
		return true;
	}
	const today = new Date();
	promo_expiration = new Date(hide_promo);

	if (today > promo_expiration) {
		window.localStorage.removeItem('hide-promo');
		promo_expiration = null;
		return true;
	}
	return false;
}

function onPopinPromo() {
	const preferences_form = document.querySelector('#preferences');

	if (preferences_form === null) {
		throw new Error('NO_PREFERENCE_FORM_FOUND');
	}

	//preference autopromo
	const input_hide_promo = preferences_form.querySelector<HTMLInputElement>('#hide-promo');
	const hide_promo_ouput = preferences_form.querySelector('output');
	if (input_hide_promo !== null && hide_promo_ouput !== null) {
		const hide_promo = !isPromoEnabled();
		if (hide_promo && promo_expiration) {
			input_hide_promo.checked = true;
		}
		else {
			var date = new Date();
			date.setMonth(date.getMonth() + 1);
			input_hide_promo.value = date.toDateString();
			hide_promo_ouput.innerText = "";
		}
	}
}

function onPopinPref() {
	const preferences_form = document.querySelector('#preferences');

	if (preferences_form === null) {
		throw new Error('NO_PREFERENCE_FORM_FOUND');
	}

	const pref_form_view = preferences_form.querySelector<HTMLInputElement>("#" + pref_view + "-view");
	if (pref_form_view !== null) {
		pref_form_view.checked = true;
	}

	const pref_hide_nan = preferences_form.querySelector<HTMLInputElement>("#hide_nan");
	if (pref_hide_nan !== null && hide_nan) {
		pref_hide_nan.checked = true;
	}

	const hide_promo_ouput = preferences_form.querySelector('output');
	if (!hide_promo_ouput) {
		throw new Error("NO_OUTPUT");
	}

	const hide_promo_checkbox = preferences_form.querySelector<HTMLInputElement>("#hide-promo");
	if (!hide_promo_checkbox) {
		throw new Error("NO_CHECKBOX_HIDE_PROMO");
	}
	if (!isPromoEnabled() && promo_expiration) {
		hide_promo_checkbox.checked = true;
		hide_promo_ouput.innerText = "La popin ne sera plus affiché jusqu'au " + promo_expiration.toLocaleDateString('fr-FR');
	}
}

document.addEventListener("a4i_openDialog", function fn(e: OpenDialogEvent) {
	const content = e.detail.content;
	if (content.id === "guide_reglages") {
		onPopinPref();
	} else if (content.id.startsWith("autopromo")) {
		onPopinPromo();
	}
}, false);
